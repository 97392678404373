<template>
  <div class="relative overflow-hidden">
    <div v-if="!files.length" class="h-full w-full bg-primary-900 opacity-5"></div>
    <div class="w-full h-full" v-else>
      <div
        v-for="(photo, i) of photos"
        :key="i"
        :class="[currentIndex === i ? 'opacity-100' : 'opacity-0']"
        class="absolute top-0 left-0 bottom-0 right-0 bg-cover bg-no-repeat bg-center transition-opacity"
        :style="`background-image: url('${photo.url}')`"
      />
      <ChevronLeftIcon
        v-if="files.length > 1"
        class="z-20 absolute inset-y-0 left-0 h-12 w-12 my-auto opacity-50 hover:opacity-100 text-primary-50 cursor-pointer"
        @click="goPrev()"
      />
      <ChevronRightIcon
        v-if="files.length > 1"
        class="z-20 absolute inset-y-0 right-0 h-12 w-12 my-auto opacity-50 hover:opacity-100 text-primary-50 cursor-pointer"
        @click="goNext()"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/solid';

export default defineComponent({
  components: { ChevronLeftIcon, ChevronRightIcon },
  props: {
    files: { type: Array, default: () => [] }
  },
  setup(props) {
    const currentIndex = ref(0);
    const goPrev = () => {
      if (currentIndex.value === 0) {
        currentIndex.value = props.files.length - 1;
      } else {
        currentIndex.value -= 1;
      }
    };
    const goNext = () => {
      if (currentIndex.value === props.files.length - 1) {
        currentIndex.value = 0;
      } else {
        currentIndex.value += 1;
      }
    };

    const photos = computed(() => {
      return props.files.map(f => ({ ...f, url: `${process.env.VUE_APP_API_URL}${f.url}` }));
    });

    return { currentIndex, goPrev, goNext, photos };
  }
});
</script>
