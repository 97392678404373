import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const RATE_QUERY = gql`
  query Rate {
    rate {
      data {
        attributes {
          pricePerAdultAndNight
          pricePerChildAndNight
        }
      }
    }
  }
`;

const useRateQuery = () => {
  const { result } = useQuery(RATE_QUERY);
  const rate = computed(() => result.value?.rate?.data?.attributes || {});

  return { rate };
};

export default useRateQuery;
