import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const OPTIONS_QUERY = gql`
  query Options {
    options {
      data {
        id
        attributes {
          name
          description
          nbPerson
          price
          validForAllNights
          photos {
            data {
              id
              attributes {
                name
                alternativeText
                url
              }
            }
          }
        }
      }
    }
  }
`;

const useOptionsQuery = () => {
  const { result } = useQuery(OPTIONS_QUERY);
  const options = computed(() => {
    return (
      result.value?.options?.data.map(t => {
        const photos = t.attributes?.photos?.data?.map(p => ({ id: p.id, ...p.attributes })) || [];
        return { id: t.id, ...t.attributes, photos };
      }) || []
    );
  });
  return { options };
};

export default useOptionsQuery;
