import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';

const formatDate = (date, formatType) => {
  return format(new Date(date), formatType, { locale: fr });
};

const useUtils = () => {
  return { formatDate };
};

export default useUtils;
