import { computed } from 'vue';
import gql from 'graphql-tag';
import { useRoute } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import isBefore from 'date-fns/isBefore';
import startOfToday from 'date-fns/startOfToday';

const WEBSITE_SETTING_QUERY = gql`
  query WebsiteSetting {
    websitesetting {
      data {
        id
        attributes {
          homePageLink {
            id
            name
            description
            icon {
              data {
                attributes {
                  url
                }
              }
            }
            page {
              data {
                id
                attributes {
                  title
                  slug
                  type
                  content
                }
              }
            }
          }
          address
        }
      }
    }
  }
`;

const useWebsiteSetting = () => {
  const { result, loading } = useQuery(WEBSITE_SETTING_QUERY);
  const homePageLinks = computed(() => {
    const links = result.value?.websitesetting?.data?.attributes?.homePageLink || [];
    return links.map(l => {
      return { ...l, iconUrl: `${process.env.VUE_APP_API_URL}${l.icon?.data?.attributes?.url}` };
    });
  });

  const address = computed(() => result.value?.websitesetting?.data?.attributes?.address || '');

  return { homePageLinks, address, loading };
};

export default useWebsiteSetting;
