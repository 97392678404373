<template>
  <div>
    <label
      :for="name"
      class="block text-base font-semibold leading-6"
      :class="[error ? 'text-red-300' : 'text-primary-300 ']"
    >
      {{ label }}
    </label>
    <div class="mt-1">
      <input
        :type="type"
        :name="name"
        class="block w-full rounded-md border-0 px-2.5 py-1.5 outline-2 outline-primary-400 bg-primary-600 text-primary-50 ring-1 ring-inset placeholder:text-primary-300"
        :class="[error ? 'ring-red-200' : 'ring-primary-500 ']"
        :placeholder="placeholder"
        v-model="value"
        :min="min"
        :max="max"
      />
      <div v-if="!disableError" class="text-sm text-red-200 h-6">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  components: {},
  emits: ['update:modelValue'],
  props: {
    type: {
      type: String,
      default: 'text',
      validator: v => ['text', 'number', 'date', 'email', 'password'].includes(v)
    },
    label: { type: String },
    name: { type: String },
    placeholder: { type: String },
    modelValue: { type: [Number, String] },
    min: { type: [Number, Date], default: 0 },
    max: { type: [Number, Date], default: 10 },
    error: { type: String },
    disableError: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const value = computed({
      get: () => {
        return props.modelValue;
      },
      set: val => {
        emit('update:modelValue', val);
      }
    });
    return { value };
  }
});
</script>
