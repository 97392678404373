import { computed, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useRateQuery from '@/composable/useRateQuery';
import useUtils from './useUtils';

const useTentBooking = () => {
  const router = useRouter();
  const route = useRoute();
  const { rate } = useRateQuery();
  const { formatDate } = useUtils();

  // Booking data
  const booking = computed(() => {
    if (route.query) {
      return {
        date: route.query.date,
        nbNights: Number(route.query.nbNights),
        nbAdults: Number(route.query.nbAdults),
        nbChildren: Number(route.query.nbChildren)
      };
    }
    return null;
  });

  const totalAdults = computed(() => {
    return booking.value
      ? rate.value.pricePerAdultAndNight * booking.value.nbAdults * booking.value.nbNights
      : 0;
  });

  const totalChildren = computed(() => {
    return booking.value
      ? rate.value.pricePerChildAndNight * booking.value.nbChildren * booking.value.nbNights
      : 0;
  });

  const total = computed(() => {
    return totalAdults.value + totalChildren.value;
  });

  // Selected tents
  const selectedTents = computed(() => {
    return Object.keys(route.query)
      .filter(key => key.startsWith('tentType'))
      .reduce((res, key) => {
        const typeId = key.slice('tentType'.length);
        return {
          ...res,
          [typeId]: { typeId: Number(typeId), nbSelected: Number(route.query[key]) }
        };
      }, {});
  });

  const getSelectedNumber = typeId => {
    return selectedTents.value[typeId]?.nbSelected || 0;
  };

  const addTent = typeId => {
    const query = { ...route.query };
    const keys = Object.keys(query);
    const key = keys.find(k => k === `tentType${typeId}`);
    if (key) {
      query[key] = Number(query[key]) + 1;
    } else {
      query[`tentType${typeId}`] = 1;
    }
    router.replace({ query });
  };

  const removeTent = typeId => {
    const query = { ...route.query };
    const keys = Object.keys(query);
    const key = keys.find(k => k === `tentType${typeId}`);
    if (key) {
      query[key] = Number(query[key]) - 1;
      if (query[key] <= 0) {
        delete query[key];
      }
      router.replace({ query });
    }
  };

  // Options data
  const options = computed(() => {
    return Object.keys(route.query)
      .filter(key => key.startsWith('option'))
      .reduce((res, key) => {
        const optionId = key.slice('option'.length);
        return {
          ...res,
          [optionId]: { optionId: Number(optionId), nbSelected: Number(route.query[key]) }
        };
      }, {});
  });

  const getOptionNumber = optionId => {
    return options.value[optionId]?.nbSelected || 0;
  };

  const addOption = optionId => {
    const query = { ...route.query };
    const keys = Object.keys(query);
    const key = keys.find(k => k === `option${optionId}`);
    if (key) {
      query[key] = Number(query[key]) + 1;
    } else {
      query[`option${optionId}`] = 1;
    }
    router.replace({ query });
  };

  const removeOption = optionId => {
    const query = { ...route.query };
    const keys = Object.keys(query);
    const key = keys.find(k => k === `option${optionId}`);
    if (key) {
      query[key] = Number(query[key]) - 1;
      if (query[key] <= 0) {
        delete query[key];
      }
      router.replace({ query });
    }
  };

  const clear = () => {
    booking.value = null;
    router.replace({ query: {} });
  };

  const goTo = bookingData => {
    const { date, nbNights, nbAdults, nbChildren } = bookingData;
    router.push({
      name: 'tent',
      query: { date: formatDate(date, 'yyyy-MM-dd'), nbNights, nbAdults, nbChildren }
    });
  };

  return {
    booking,
    addTent,
    getSelectedNumber,
    removeTent,
    clear,
    totalAdults,
    totalChildren,
    total,
    selectedTents,
    goTo,
    options,
    addOption,
    removeOption,
    getOptionNumber
  };
};

export default useTentBooking;
