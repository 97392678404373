import { computed } from 'vue';
import gql from 'graphql-tag';
import { useRoute } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import isBefore from 'date-fns/isBefore';
import startOfToday from 'date-fns/startOfToday';

const AVAILABLE_TENTS_QUERY = gql`
  query AvailableTents($where: AvailableTentWhereInput!) {
    availableTents(where: $where) {
      typeId
      name
      capacity
      description
      nbAvailable
      photos {
        id
        attributes {
          name
          url
          alternativeText
        }
      }
    }
  }
`;

const useAvailableTentsQuery = () => {
  const route = useRoute();

  const variables = computed(() => {
    return {
      where: {
        date: route.query.date,
        nbNights: Number(route.query.nbNights),
        nbAdults: Number(route.query.nbAdults),
        nbChildren: Number(route.query.nbChildren)
      }
    };
  });

  const isValidSearch = computed(
    () =>
      route.query.date &&
      !isBefore(new Date(route.query.date), startOfToday()) &&
      route.query.nbNights &&
      route.query.nbNights !== '0' &&
      route.query.nbAdults &&
      route.query.nbAdults !== '0'
  );

  const { result, loading, error } = useQuery(AVAILABLE_TENTS_QUERY, variables, {
    skip: () => !isValidSearch.value
  });
  const availableTents = computed(() => result.value?.availableTents || []);

  return { availableTents, loading, isValidSearch, error };
};

export default useAvailableTentsQuery;
