<template>
  <div class="flex flex-col divide-y divide-primary-700">
    <div class="pb-10">
      <h1 class="mb-8">Résumé de votre réservation</h1>
      <div v-if="!tents?.length" class="rounded-md bg-red-50 p-4 mb-8">
        <div class="flex">
          <div class="flex-shrink-0">
            <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div class="ml-3">
            <h3 class="text-base font-semibold text-red-800">
              Les tentes ne sont plus disponibles à la réservation
            </h3>
          </div>
        </div>
      </div>
      <div
        v-if="tents.filter(t => !t.error && !t.available).length"
        class="rounded-md bg-red-50 p-4 mb-8"
      >
        <div class="flex">
          <div class="flex-shrink-0">
            <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div class="ml-3">
            <h3 class="text-base font-semibold text-red-800">
              Certaines tentes ne sont plus disponibles
            </h3>
            <div class="text-base text-red-700">
              <ul role="list" class="list-disc pl-5">
                <li v-for="(tent, i) in tents.filter(t => !t.error && !t.available)" :key="i">
                  {{ tent.name }}
                  <span v-if="tent.nbAvailable === 1">: 1 tente restante</span>
                  <span v-if="tent.nbAvailable > 1">: {{ tent.nbAvailable }} tentes restantes</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-6">
        <div
          v-for="tent in tents.filter(t => !t.error)"
          :key="tent.typeId"
          class="flex flex-row gap-4"
        >
          <PhotoSlider
            class="w-44 rounded"
            :files="tent.photos?.map(p => ({ ...p, ...p.attributes })).slice(0, 1)"
          />
          <div>
            <div class="" v-if="!tent.available">
              Mince ! {{ tent.nbSelected - tent.nbAvailable }}
              <span v-if="tent.nbSelected - tent.nbAvailable === 1">
                tente n'est plus disponible !
              </span>
              <span v-else>tentes ne sont plus disponibles !</span>
            </div>
            <div class="text-lg font-semibold">{{ tent.nbSelected }} x {{ tent.name }}</div>
            <div class="text-primary-200">
              {{ tent.capacity * tent.nbSelected }} personne{{ tent.capacity > 1 ? 's' : '' }}
            </div>
            <div class="text-primary-200">
              Du {{ formatDate(startDate, 'dd/MM/yyyy') }} au
              {{ formatDate(endDate, 'dd/MM/yyyy') }}
            </div>
            <Button type="white" @click="removeTent(tent.typeId)" class="mt-4">Supprimer</Button>
          </div>
        </div>
      </div>
    </div>
    <div class="py-8">
      <h2 class="pb-6">Options</h2>
      <div class="flex flex-col gap-6">
        <div
          v-for="option in allOptions"
          :key="option.id"
          :class="[
            getOptionNumber(option.id)
              ? 'border-2 border-primary-400 drop-shadow-xl'
              : 'border border-primary-600 drop-shadow-sm'
          ]"
          class="flex flex-row bg-primary-700 rounded-lg"
        >
          <PhotoSlider class="w-44 rounded-l-lg" :files="option.photos.slice(0, 1)" />
          <div class="flex flex-col flex-1 px-7 pt-4 pb-5">
            <div class="flex-1">
              <div class="text-lg font-semibold">{{ option.name }}</div>
              <div v-if="option.validForAllNights" class="text-primary-200">
                {{ option.price }} € par nuit et par personne
              </div>
              <div v-else class="text-primary-200">{{ option.price }} €</div>
              <div class="mt-2">{{ option.description }}</div>
            </div>
            <div class="flex flex-col items-end">
              <div v-if="option.validForAllNights">
                <Button
                  v-if="!getOptionNumber(option.id)"
                  type="secondary"
                  size="sm"
                  @click="addOption(option.id)"
                >
                  Ajouter
                </Button>
                <Button v-else type="secondary" size="sm" @click="removeOption(option.id)">
                  Supprimer
                </Button>
              </div>
              <div
                v-else
                class="flex flex-row divide-x divide-primary-700 mt-2 border border-solid border-primary-600 rounded-md"
              >
                <Button
                  groupPosition="left"
                  type="secondary"
                  size="sm"
                  :disabled="!getOptionNumber(option.id)"
                  @click="removeOption(option.id)"
                >
                  <template #icon> <MinusIcon /> </template>
                </Button>
                <div class="w-8 text-center">{{ getOptionNumber(option.id) }}</div>
                <Button
                  groupPosition="right"
                  type="secondary"
                  size="sm"
                  @click="addOption(option.id)"
                >
                  <template #icon> <PlusIcon /> </template>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-8">
      <div class="flex flex-row self-stretch justify-between">
        <div>{{ booking?.nbAdults || 0 }} x Adultes</div>
        <div>{{ totalAdults }} €</div>
      </div>
      <div class="flex flex-row self-stretch justify-between">
        <div>{{ booking?.nbChildren || 0 }} x Enfants</div>
        <div>{{ totalChildren }} €</div>
      </div>
      <div
        v-for="option in selectedOptions"
        :key="option.id"
        class="flex flex-row self-stretch justify-between"
      >
        <div v-if="option.validForAllNights">{{ option.name }}</div>
        <div v-else>{{ option.nbSelected }} x {{ option.name }}</div>
        <div>{{ option.totalPrice }} €</div>
      </div>
      <div class="flex flex-row self-stretch justify-between mt-4">
        <div class="font-semibold">Total</div>
        <div class="font-semibold">{{ total }} €</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import useTentBooking from '@/composable/useTentBooking';
import addDays from 'date-fns/addDays';
import useUtils from '@/composable/useUtils';
import Button from '@/components/Button';
import PhotoSlider from '@/components/PhotoSlider';
import useCheckTentsAvailabilityQuery from '@/composable/useCheckTentsAvailabilityQuery';
import { XCircleIcon, PlusIcon, MinusIcon } from '@heroicons/vue/20/solid';
import useOptionsQuery from '@/composable/useOptionsQuery';

export default defineComponent({
  components: { Button, PhotoSlider, XCircleIcon, PlusIcon, MinusIcon },
  setup() {
    const { options: allOptions } = useOptionsQuery();
    const { availableTents } = useCheckTentsAvailabilityQuery();
    const {
      booking,
      removeTent,
      totalAdults,
      totalChildren,
      options,
      addOption,
      removeOption,
      getOptionNumber
    } = useTentBooking();
    const { formatDate } = useUtils();
    const startDate = computed(() => new Date(booking.value?.date));
    const endDate = computed(() => addDays(startDate.value, booking.value?.nbNights));

    const selectedOptions = computed(() => {
      return Object.keys(options.value).reduce((res, optionId) => {
        const optionData = allOptions.value.find(o => o.id === optionId);
        if (!optionData) return res;

        let totalPrice = optionData.price * options.value[optionId].nbSelected;
        if (optionData.validForAllNights) {
          totalPrice =
            optionData.price *
            (booking.value.nbAdults + booking.value.nbChildren) *
            booking.value.nbNights;
        }

        return [
          ...res,
          {
            id: optionId,
            name: optionData.name,
            validForAllNights: optionData.validForAllNights,
            nbSelected: options.value[optionId].nbSelected,
            totalPrice
          }
        ];
      }, []);
    });

    const total = computed(() => {
      return (
        totalAdults.value +
        totalChildren.value +
        selectedOptions.value.reduce((res, o) => res + o.totalPrice, 0)
      );
    });

    return {
      tents: availableTents,
      booking,
      startDate,
      endDate,
      formatDate,
      removeTent,
      total,
      totalAdults,
      totalChildren,
      selectedOptions,
      allOptions,
      addOption,
      removeOption,
      getOptionNumber
    };
  }
});
</script>
