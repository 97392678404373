<template>
  <div class="flex flex-col gap-12">
    <h1 v-if="bookingData?.paid">Confirmation de votre réservation</h1>
    <h1 v-else>Votre réservation</h1>
    <div v-if="bookingData">
      <div>
        Code de réservation : <span class="font-semibold">{{ bookingCode }}</span>
      </div>
      <div>
        Réservation pour {{ bookingData.nbAdults }} adulte{{ bookingData.nbAdults > 1 ? 's' : '' }}
        <span v-if="bookingData.nbChildren">
          et {{ bookingData.nbChildren }} enfant{{ bookingData.nbChildren > 1 ? 's' : '' }}
        </span>
        du {{ formatDate(bookingData.startDate, 'eeee dd MMM yyyy') }} au
        {{ formatDate(bookingData.endDate, 'eeee dd MMM yyyy') }}.
      </div>
      <div v-for="option in options" :key="option.id">
        {{ option.nbSelected }} x {{ option.name }}
      </div>
    </div>
    <div class="flex flex-col gap-6">
      <div v-for="tent in tents" :key="tent.id" class="flex flex-row gap-4">
        <PhotoSlider
          class="w-44 h-32 rounded"
          :files="tent.photos?.map(p => ({ ...p, ...p.attributes })).slice(0, 1)"
        />
        <div>
          <div class="text-lg font-semibold">{{ tent.nbSelected }} x {{ tent.name }}</div>
          <div class="text-primary-200">
            {{ tent.capacity * tent.nbSelected }} personne{{ tent.capacity > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import PhotoSlider from '@/components/PhotoSlider';
import useUtils from '@/composable/useUtils';
import addDays from 'date-fns/addDays';

const CONFIRM_BOOKING_MUTATION = gql`
  mutation ConfirmBooking($bookingCode: String!) {
    confirmBooking(bookingCode: $bookingCode) {
      booking {
        id
        attributes {
          paid
          canceled
          date
          nbNights
          nbAdults
          nbChildren
          price
          createdAt
        }
      }
      tentTypes {
        id
        name
        capacity
        nbSelected
        photos {
          id
          attributes {
            name
            url
            alternativeText
          }
        }
      }
      options {
        id
        name
        nbSelected
      }
    }
  }
`;

export default defineComponent({
  components: { PhotoSlider },
  setup() {
    const route = useRoute();
    const { formatDate } = useUtils();
    const bookingCode = computed(() => route.query.id);

    const customer = ref(null);
    const bookingData = ref(null);
    const tents = ref([]);
    const options = ref([]);

    const { mutate: confirmBookingMutation } = useMutation(CONFIRM_BOOKING_MUTATION);
    const confirmMutation = async () => {
      const { data } = await confirmBookingMutation({ bookingCode: bookingCode.value });
      tents.value = data?.confirmBooking?.tentTypes || [];
      options.value = data?.confirmBooking?.options || [];

      const booking = data?.confirmBooking?.booking;
      if (booking) {
        const { nbNights, date, nbAdults, nbChildren, price, paid, canceled } = booking.attributes;
        bookingData.value = {
          id: booking.id,
          nbNights,
          nbAdults,
          nbChildren,
          price,
          paid,
          canceled,
          startDate: new Date(date),
          endDate: addDays(new Date(date), nbNights)
        };
      }
    };
    confirmMutation();

    return {
      bookingCode,
      customer,
      bookingData,
      tents,
      formatDate,
      options
    };
  }
});
</script>
