<template>
  <div>
    <h1 class="mb-8">Découvrez nos tentes</h1>
    <div class="grid grid-cols-1 gap-3 2xl:grid-cols-auto xl:grid-cols-3 sm:grid-cols-2">
      <div
        v-for="tent in tentTypes"
        :key="tent.id"
        class="flex flex-col bg-primary-700 rounded-lg drop-shadow-xl"
      >
        <PhotoSlider
          class="rounded-t-md h-64 lg:h-56 md:h-52 sm:h-40"
          :files="tent.photos.data.map(p => ({ ...p, ...p.attributes }))"
        />
        <div class="flex flex-col p-5 gap-3">
          <div class="flex-1">
            <div class="text-2xl">{{ tent.name }}</div>
            <div class="text-primary-200">
              {{ tent.capacity }} personne{{ tent.capacity > 1 ? 's' : '' }} maximum
            </div>
          </div>
          <div>
            {{ tent.description }}
          </div>
        </div>
      </div>
    </div>
    <h1 class="mt-24 mb-8">Et nos options !</h1>
    <div class="grid grid-cols-1 gap-3 2xl:grid-cols-auto xl:grid-cols-3 sm:grid-cols-2">
      <div
        v-for="option in options"
        :key="option.id"
        class="flex flex-col bg-primary-700 rounded-lg drop-shadow-xl"
      >
        <PhotoSlider class="rounded-t-md h-64 lg:h-56 md:h-52 sm:h-40" :files="option.photos" />
        <div class="flex flex-col p-5">
          <div class="flex-1">
            <div class="text-2xl">{{ option.name }}</div>
          </div>
          <div>
            {{ option.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import useTentTypesQuery from '@/composable/useTentTypesQuery';
import PhotoSlider from '@/components/PhotoSlider';
import useOptionsQuery from '@/composable/useOptionsQuery';

export default defineComponent({
  components: { PhotoSlider },
  setup() {
    const { tentTypes } = useTentTypesQuery();
    const { options } = useOptionsQuery();
    return { tentTypes, options };
  }
});
</script>
