<template>
  <div class="flex flex-col bg-primary-700 rounded-lg drop-shadow-xl p-8 items-center">
    <div
      class="justify-self-end bg-contain bg-bottom bg-no-repeat h-16 w-16"
      :style="`background-image: url('${iconUrl}')`"
    />
    <h2 class="font-semibold mt-2 mb-6 text-primary-100">{{ title }}</h2>
    <div class="flex-1">
      <slot />
    </div>
    <a :href="link" class="underline font-semibold mt-3">En savoir plus</a>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
  props: {
    title: { type: String },
    iconUrl: { type: String },
    link: { type: String }
  },
  setup() {
    return {};
  }
});
</script>
