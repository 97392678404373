<template>
  <div class="flex flex-col gap-20">
    <div>
      <h1>Découvrez notre concept</h1>
      <div class="grid md:grid-cols-3 gap-4 mt-4">
        <page-link
          v-for="link in homePageLinks"
          :key="link.id"
          :title="link.name"
          :iconUrl="link.iconUrl"
        >
          {{ link.description }}
        </page-link>
      </div>
    </div>
    <div class="flex flex-col">
      <h1 class="mb-6">Réservez votre séjour chez nous</h1>
      <div
        class="flex flex-col gap-4 md:flex-row md:items-end sm:self-start md:gap-2 bg-primary-700 px-7 pt-4 pb-5 rounded-t-lg drop-shadow-xl"
      >
        <div class="grid grid-cols-2 sm:flex gap-2">
          <Input
            label="Date"
            name="date"
            type="date"
            v-model="bookingFormValue.date"
            disableError
          />
          <Input
            label="Nuits"
            name="nbNights"
            type="number"
            v-model="bookingFormValue.nbNights"
            disableError
          />
          <Input
            label="Adultes"
            name="nbAdults"
            type="number"
            v-model="bookingFormValue.nbAdults"
            disableError
          />
          <Input
            label="Enfants"
            name="nbChildren"
            type="number"
            v-model="bookingFormValue.nbChildren"
            disableError
          />
        </div>

        <Button
          @click="search()"
          :disabled="
            !bookingFormValue.date || !bookingFormValue.nbAdults || !bookingFormValue.nbAdults
          "
        >
          Rechercher
        </Button>
      </div>
      <div
        class="relative flex flex-row gap-3 bg-primary-700 px-4 pt-4 pb-6 rounded-b-lg sm:rounded-tr-lg drop-shadow-xl"
      >
        <ChevronLeftIcon
          class="hidden lg:block h-12 w-12 my-auto opacity-50 hover:opacity-100 text-primary-50 cursor-pointer"
          @click="goPrev()"
        />
        <div
          v-for="(tent, i) in tentTypes"
          :key="tent.id"
          class="flex flex-col lg:flex-row items-center md:gap-12 flex-1"
          :class="[currentIndex === i ? 'block' : 'hidden']"
        >
          <PhotoSlider
            class="lg:basis-2/3 h-64 md:h-72 w-full rounded-sm"
            :files="tent.photos.map(p => ({ ...p, ...p.attributes }))"
          />
          <div class="basis-1/3 text-center py-8 flex flex-row gap-2">
            <ChevronLeftIcon
              class="lg:hidden h-8 w-8 my-auto opacity-50 hover:opacity-100 text-primary-50 cursor-pointer"
              @click="goPrev()"
            />
            <div class="flex-1">
              <h2 class="mb-2 font-semibold">{{ tent.name }}</h2>
              {{ tent.description }}
            </div>
            <ChevronRightIcon
              class="lg:hidden h-8 w-8 my-auto opacity-50 hover:opacity-100 text-primary-50 cursor-pointer"
              @click="goNext()"
            />
          </div>
        </div>
        <ChevronRightIcon
          class="hidden lg:block ml-6 h-12 w-12 my-auto opacity-50 hover:opacity-100 text-primary-50 cursor-pointer"
          @click="goNext()"
        />
      </div>
    </div>
    <div>
      <h1>Où nous trouver</h1>
      <div v-html="parse(address)" class="text-lg mt-6" />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref } from 'vue';
import PageLink from '@/components/PageLink';
import Input from '@/components/Input';
import Button from '@/components/Button';
import useTentBooking from '@/composable/useTentBooking';
import format from 'date-fns/format';
import useTentTypesQuery from '@/composable/useTentTypesQuery';
import PhotoSlider from '@/components/PhotoSlider.vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/solid';
import useWebsiteSetting from '@/composable/useWebsiteSetting';
import { parse } from 'marked';

export default defineComponent({
  components: { PageLink, Input, Button, PhotoSlider, ChevronLeftIcon, ChevronRightIcon },
  setup() {
    const { homePageLinks, address } = useWebsiteSetting();
    const { goTo } = useTentBooking();
    const { tentTypes: tentTypesResult } = useTentTypesQuery();
    const tentTypes = computed(() => {
      return (
        tentTypesResult.value?.map(tt => ({
          ...tt,
          photos: tt.photos.data.length ? [tt.photos.data[0]] : []
        })) || []
      );
    });

    const bookingFormValue = reactive({
      date: format(new Date(), 'yyyy-MM-dd'),
      nbNights: 1,
      nbAdults: 2,
      nbChildren: 0
    });

    const search = () => {
      goTo(bookingFormValue);
    };

    const currentIndex = ref(0);
    const goPrev = () => {
      if (currentIndex.value === 0) {
        currentIndex.value = tentTypes.value.length - 1;
      } else {
        currentIndex.value -= 1;
      }
    };
    const goNext = () => {
      if (currentIndex.value === tentTypes.value.length - 1) {
        currentIndex.value = 0;
      } else {
        currentIndex.value += 1;
      }
    };

    return {
      bookingFormValue,
      search,
      tentTypes,
      currentIndex,
      goPrev,
      goNext,
      homePageLinks,
      address,
      parse
    };
  }
});
</script>
