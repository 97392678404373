import { createApp, h, provide } from 'vue';
import App from './App.vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import GeneratedView from '@/views/GeneratedView';
import gql from 'graphql-tag';
import { createRouter, createWebHistory } from 'vue-router';
import apolloClient from './apolloClient';
import CartView from '@/views/CartView';
import BookingView from '@/views/BookingView';

import './assets/tailwind.css';

const routes = [
  {
    path: '/',
    name: 'home',
    component: GeneratedView
  },
  {
    path: '/tents',
    name: 'tent',
    component: GeneratedView
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartView
  },
  {
    path: '/booking',
    name: 'booking',
    component: BookingView
  }
];

const PAGES_QUERY = gql`
  query Pages {
    pages {
      data {
        id
        attributes {
          title
          type
          slug
          content
        }
      }
    }
  }
`;

apolloClient.query({ query: PAGES_QUERY }).then(({ data }) => {
  for (const page of data.pages.data.filter(
    p =>
      !!p.attributes.slug &&
      !['accueil', 'tentes'].includes(p.attributes.type) &&
      !['/', '/tents'].includes(p.attributes.slug)
  )) {
    routes.push({
      name: page.attributes.title,
      path: page.attributes.slug,
      component: GeneratedView
    });
  }

  createApp({
    setup() {
      provide(DefaultApolloClient, apolloClient);
    },
    render: () => h(App)
  })
    .use(
      createRouter({
        history: createWebHistory(),
        routes
      })
    )
    .mount('#app');
});
