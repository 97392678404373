<template>
  <cart-layout>
    <booking-success v-if="isSuccess" />
  </cart-layout>
</template>

<script>
import { computed, defineComponent } from 'vue';
import CartLayout from '@/components/layout/CartLayout';
import { useRoute } from 'vue-router';
import BookingSuccess from '@/components/booking/BookingSuccess';

export default defineComponent({
  components: { CartLayout, BookingSuccess },
  setup() {
    const route = useRoute();
    const isSuccess = computed(() => {
      return !!route.query.success && route.query.success === 'true';
    });
    const isCanceled = computed(() => {
      return !!route.query.cancel && route.query.cancel === 'true';
    });
    return { isSuccess, isCanceled };
  }
});
</script>
