import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import useTentBooking from './useTentBooking';

const CHECK_TENTS_AVAILABILITY = gql`
  query CheckTentsAvailability($input: TentsAvailabilityInput!) {
    checkTentsAvailability(input: $input) {
      typeId
      name
      capacity
      available
      nbSelected
      nbAvailable
      error
      photos {
        attributes {
          url
        }
      }
    }
  }
`;

const useCheckTentsAvailabilityQuery = () => {
  const { selectedTents, booking } = useTentBooking();

  const variables = computed(() => {
    return {
      input: {
        date: booking.value.date,
        nbNights: booking.value.nbNights,
        tents: Object.values(selectedTents.value)
      }
    };
  });

  const { result, error } = useQuery(CHECK_TENTS_AVAILABILITY, variables);
  const availableTents = computed(() => result.value?.checkTentsAvailability || []);

  return { availableTents, error };
};

export default useCheckTentsAvailabilityQuery;
