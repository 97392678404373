<template>
  <div>
    <div class="flex flex-row items-end relative h-72">
      <div
        class="flex-1 bg-contain bg-no-repeat bg-left-bottom h-full"
        style="background-image: url('./img/forest.png')"
      />
      <div
        class="hidden sm:block flex-1 bg-contain bg-no-repeat bg-right-bottom h-4/5"
        style="background-image: url('./img/tree.png')"
      />
    </div>
    <div class="flex flex-col items-center bg-primary-900 text-base text-primary-200">
      <div class="flex-1 flex flex-wrap flex-row w-5/6 lg:w-2/3 2xl:w-[56rem] pt-8 pb-8 gap-12">
        <div
          class="bg-contain bg-no-repeat bg-center h-16 w-16 md:w-32 self-center"
          style="background-image: url('./img/logo_light.png')"
        />
        <ul class="lg:ml-12">
          <li><a href="/">Accueil</a></li>
          <li><a href="/tent">Tentes</a></li>
          <li><a href="/activity">Loisirs</a></li>
          <li><a href="/event">Soirées</a></li>
        </ul>
        <ul class="flex-1">
          <li><a href="/plans">Formules</a></li>
          <li><a href="/">Mentions légales</a></li>
          <li><a href="/">CGV</a></li>
        </ul>
        <div v-html="parse(address)" />
      </div>
      <div
        class="border-t border-primary-800 text-primary-700 pt-4 pb-6 w-5/6 lg:w-2/3 2xl:w-[56rem] text-center text-sm"
      >
        Copyright © 2022 | Tous droits réservés Nuits Insolites Avalon
      </div>
    </div>
  </div>
</template>

<script>
import useWebsiteSetting from '@/composable/useWebsiteSetting';
import { defineComponent } from 'vue';
import { parse } from 'marked';

export default defineComponent({
  components: {},
  setup() {
    const { address } = useWebsiteSetting();
    return { address, parse };
  }
});
</script>
