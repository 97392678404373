<template>
  <div class="flex flex-col w-full">
    <TentBookingSearchBar />
    <AvailableTents v-if="isSearching" />
    <Tents v-else />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import TentBookingSearchBar from '@/components/tents/TentBookingSearchBar.vue';
import AvailableTents from '@/components/tents/AvailableTents';
import useAvailableTentsForm from '@/composable/useAvailableTentsForm';
import Tents from '@/components/tents/Tents';

export default defineComponent({
  components: { TentBookingSearchBar, AvailableTents, Tents },
  setup: () => {
    const { isSearching } = useAvailableTentsForm();
    return { isSearching };
  }
});
</script>
