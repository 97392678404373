<template>
  <div
    class="min-h-screen top-0 bottom-0 bg-primary-800 text-primary-50 text-lg bg-contain"
    style="background-image: url('./img/wallpaper.png')"
  >
    <div class="min-h-screen relative flex flex-col z-10">
      <div
        class="absolute bg-contain bg-no-repeat h-24 w-24 top-6 left-4 md:left-20 xl:h-36 xl:w-40 xl:left-36 lg:h-32 lg:w-32 lg:left-24"
        style="background-image: url('./img/logo.png')"
      />
      <div
        class="flex flex-col flex-1 z-10 pt-28 pb-4 px-6 md:px-20 2xl:pb-20 2xl:px-88 xl:pt-44 xl:pb-12 xl:px-36 lg:pt-36 lg:px-24 md:pt-28 md:pb-10"
      >
        <a class="flex flex-row gap-1 self-start items-center text-sm mb-12" href="/">
          <ArrowLeftIcon class="h-3 w-3" /> Retour au site
        </a>
        <slot />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { ArrowLeftIcon } from '@heroicons/vue/20/solid';
import Footer from '@/components/Footer';

export default defineComponent({
  components: { ArrowLeftIcon, Footer },
  setup() {
    return {};
  }
});
</script>
