<template>
  <cart-layout>
    <div class="flex flex-col gap-12 lg:gap-20 lg:items-start lg:flex-row">
      <BookingSummary class="lg:w-1/2" />
      <InformationCard class="lg:w-1/2" />
    </div>
  </cart-layout>
</template>

<script>
import { defineComponent } from 'vue';
import CartLayout from '@/components/layout/CartLayout';
import BookingSummary from '@/components/cart/BookingSummary';
import InformationCard from '@/components/cart/InformationCard';

export default defineComponent({
  components: { CartLayout, BookingSummary, InformationCard },
  setup() {
    return {};
  }
});
</script>
