import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const TENT_TYPES_QUERY = gql`
  query TentTypes {
    tentTypes {
      data {
        id
        attributes {
          name
          capacity
          description
          photos {
            data {
              id
              attributes {
                name
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;

const useTentTypesQuery = () => {
  const { result } = useQuery(TENT_TYPES_QUERY);
  const tentTypes = computed(() => {
    return result.value?.tentTypes?.data.map(t => ({ id: t.id, ...t.attributes })) || [];
  });
  return { tentTypes };
};

export default useTentTypesQuery;
