<template>
  <div>
    <nav
      class="z-10 flex flex-row justify-end items-start gap-1 md:py-2 md:px-16 bg-gradient-to-b from-primary-900 h-56"
    >
      <div
        class="z-20 flex flex-col w-5/6 items-end md:hidden pt-6 pb-8 px-8 text-right absolute"
        :class="[isOpened ? 'bg-primary-900/90 bottom-0 h-full' : '']"
      >
        <x-mark-icon v-if="isOpened" class="h-8 w-8" @click="toggleMenu()" />
        <bars3-icon v-else class="h-8 w-8" @click="toggleMenu()" />
        <div v-if="isOpened" class="flex flex-col gap-4 mt-8">
          <router-link
            v-for="item in menuItems"
            :key="item.id"
            v-slot="{ isActive }"
            :to="item.slug"
            class="font-title text-xl text-primary-50 hover:text-white"
            @click="toggleMenu()"
          >
            <span :class="{ 'text-white': isActive }">{{ item.title }}</span>
          </router-link>
        </div>
      </div>
      <router-link
        v-for="item in menuItems"
        :key="item.id"
        v-slot="{ isActive }"
        :to="item.slug"
        class="hidden md:block font-title pt-8 pb-2 px-4 text-xl text-primary-50 hover:text-white"
      >
        <span :class="{ 'text-white': isActive }">{{ item.title }}</span>
      </router-link>
    </nav>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/solid';

const MENU_QUERY = gql`
  query Menu {
    menu {
      data {
        id
        attributes {
          items {
            name
            page {
              data {
                attributes {
                  title
                  slug
                  type
                  content
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default defineComponent({
  components: { Bars3Icon, XMarkIcon },
  setup() {
    // Menu
    const { result: menuResult } = useQuery(MENU_QUERY);
    const getMenuSlug = m => {
      if (m.page?.data?.attributes?.type === 'accueil') {
        return '/';
      }
      if (m.page?.data?.attributes?.type === 'tentes') {
        return '/tents';
      }
      return m.page?.data?.attributes?.slug;
    };

    const menuItems = computed(
      () =>
        menuResult.value?.menu?.data?.attributes?.items
          .map(m => ({
            title: m.name,
            slug: getMenuSlug(m)
          }))
          .filter(m => !!m.slug) ?? []
    );

    const isOpened = ref(false);
    const toggleMenu = () => (isOpened.value = !isOpened.value);

    return { menuItems, isOpened, toggleMenu };
  }
});
</script>
