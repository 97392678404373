import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core';
import { ApolloLink, concat } from 'apollo-link';

const httpLink = new HttpLink({
  uri: `${process.env.VUE_APP_API_URL}/graphql`
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = process.env.VUE_APP_API_TOKEN;
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  });
  return forward(operation);
});

export default new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === 'development'
});
