<template>
  <main-layout>
    <div v-html="content" class="xl:w-2/3" />
    <home-content v-if="page.type === 'accueil'" class="mt-12" />
    <tent-booking v-else-if="page.type === 'tentes'" />
  </main-layout>
</template>

<script>
import { parse } from 'marked';
import { computed, defineComponent, toRefs } from 'vue';
import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';
import MainLayout from '@/components/MainLayout';
import TentBooking from '@/components/tents/TentBooking';
import HomeContent from '@/components/HomeContent';

const PAGE_BY_TYPE_OR_SLUG = gql`
  query PageByTypeOrSlug($type: String!, $slug: String) {
    pageByTypeOrSlug(type: $type, slug: $slug) {
      id
      attributes {
        title
        type
        slug
        content
      }
    }
  }
`;

export default defineComponent({
  components: { MainLayout, TentBooking, HomeContent },
  setup() {
    const route = useRoute();
    const { path } = toRefs(route);
    const type = computed(() => {
      if (path?.value === '/') {
        return 'accueil';
      }
      if (path?.value === '/tents') {
        return 'tentes';
      }
      return 'autre';
    });

    const variables = computed(() => ({ type: type.value, slug: path?.value }));
    const { result } = useQuery(PAGE_BY_TYPE_OR_SLUG, variables);
    const page = computed(() => {
      return result.value?.pageByTypeOrSlug?.attributes || {};
    });

    const content = computed(() => parse(page.value?.content || ''));

    return { page, content };
  }
});
</script>
