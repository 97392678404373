import { reactive, computed } from 'vue';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import startOfToday from 'date-fns/startOfToday';
import { useRoute, useRouter } from 'vue-router';

const useAvailableTentsForm = () => {
  const router = useRouter();
  const route = useRoute();

  const defaultFormValue = {
    date: format(new Date(), 'yyyy-MM-dd'),
    nbNights: 0,
    nbAdults: 0,
    nbChildren: 0
  };

  const formValue = reactive({
    date: route.query.date || defaultFormValue.date,
    nbNights: Number(route.query.nbNights) || defaultFormValue.nbNights,
    nbAdults: Number(route.query.nbAdults) || defaultFormValue.nbAdults,
    nbChildren: Number(route.query.nbChildren) || defaultFormValue.nbChildren
  });

  const isSearching = computed(
    () => route.query?.date || route.query?.nbNights || route.query?.nbChildren
  );

  const openForm = () => {
    const { date, nbNights, nbAdults, nbChildren } = formValue;
    router.replace({ name: 'tent', query: { date, nbNights, nbAdults, nbChildren } });
  };

  const clearForm = () => {
    router.replace({ name: 'tent', query: {} });
  };

  const isFormValid = computed(() => {
    const { date, nbNights, nbAdults } = formValue;
    return !!date && !isBefore(new Date(date), startOfToday()) && !!nbNights && !!nbAdults;
  });

  const search = async () => {
    if (isFormValid.value) {
      const { date, nbNights, nbAdults, nbChildren } = formValue;
      router.replace({
        name: 'tent',
        query: { date, nbNights, nbAdults, nbChildren }
      });
    }
  };

  return { formValue, openForm, clearForm, search, isSearching, isFormValid };
};

export default useAvailableTentsForm;
