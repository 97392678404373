<template>
  <div
    class="bg-primary-800 text-primary-50 text-lg bg-contain bg-repeat-y"
    style="background-image: url('./img/wallpaper.png')"
  >
    <div
      class="z-0 inset-0 bottom-80 bg-cover bg-no-repeat bg-bottom absolute 2xl:bottom-6 lg:bottom-32 md:bottom-64 sm:bottom-72"
      style="background-image: url('./img/header.png')"
    />
    <div class="relative z-10">
      <Menu />
      <div
        @click="goToHome()"
        class="absolute bg-contain bg-no-repeat h-36 w-40 top-16 left-16 xl:h-60 xl:w-64 lg:h-48 lg:w-56 md:h-40 md:w-48 sm:h-36 sm:w-40 cursor-pointer"
        style="background-image: url('./img/logo.png')"
      />
      <div
        class="bg-contain bg-no-repeat bg-center h-40 w-full mt-6 xl:h-48 xl:mt-10 md:h-40 md:mt-10 sm:h-40 sm:mt-6"
        style="background-image: url('./img/slogan.png')"
      />
      <div
        class="flex flex-col z-10 pt-16 pb-4 px-4 2xl:pb-20 2xl:px-0 2xl:w-[56rem] 2xl:m-auto xl:pt-24 xl:pb-12 xl:px-56 lg:px-32 md:pt-16 md:pb-10 md:px-24 sm:pt-16 sm:px-20"
      >
        <slot />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Menu from '@/components/Menu';
import { useRouter } from 'vue-router';
import Footer from './Footer';

export default defineComponent({
  components: { Menu, Footer },
  setup() {
    const router = useRouter();
    const goToHome = () => router.push('/');
    return { goToHome };
  }
});
</script>
