<template>
  <button
    type="button"
    :disabled="disabled"
    :class="[
      { 'cursor-default': disabled, 'shadow-sm': !disabled },
      colorClasses,
      type !== 'white' ? sizeClasses[size] : '',
      roundedClasses
    ]"
    class="flex flex-row items-center font-semibold justify-center focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
  >
    <div v-if="$slots['icon']" :class="[iconSizesClasses[size]]">
      <slot name="icon"></slot>
    </div>
    <div v-else class="flex flex-row items-center">
      <span v-if="loading">
        <!-- <LoadingIcon class="mr-3 h-5 w-5 animate-spin" /> -->
      </span>
      <div
        v-else-if="$slots['icon-before']"
        :class="[iconSizesClasses[size], iconLeftMarginClasses[size]]"
      >
        <slot name="icon-before"></slot>
      </div>
      <slot />
    </div>
  </button>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  components: {},
  props: {
    type: { type: String, default: 'primary' },
    size: {
      type: String,
      default: 'base',
      validator: v => ['xs', 'sm', 'base', 'lg', 'xl'].includes(v)
    },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    groupPosition: { type: String, default: '' }
  },
  setup(props) {
    const colorClasses = computed(() => {
      if (props.type === 'secondary') {
        if (props.disabled) {
          return 'bg-primary-600 text-primary-800';
        }
        return 'bg-primary-400 text-primary-900 hover:bg-primary-500 focus-visible:outline-primary-500';
      }
      if (props.type === 'white') {
        if (props.disabled) {
          return 'text-primary-300';
        }
        return 'text-primary-100 hover:text-primary-50';
      }
      if (props.disabled) {
        return 'bg-yellow-700 text-yellow-900';
      }
      return 'bg-yellow-500 text-yellow-900 hover:bg-yellow-400';
    });

    const sizeClasses = {
      xs: 'px-2 py-1 text-base',
      sm: 'px-2 py-1 text-lg',
      base: 'px-2.5 py-1.5 text-lg',
      lg: 'px-3 py-2 text-lg',
      xl: 'px-3.5 py-2.5 text-lg'
    };

    const roundedClasses = computed(() => {
      if (props.groupPosition === 'left') {
        if (['xs', 'sm'].includes(props.size)) return 'rounded-l';
        else return 'rounded-l-md';
      }
      if (props.groupPosition === 'right') {
        if (['xs', 'sm'].includes(props.size)) return 'rounded-r';
        else return 'rounded-r-md';
      }
      if (props.groupPosition !== 'middle') {
        if (['xs', 'sm'].includes(props.size)) return 'rounded';
        else return 'rounded-md';
      }
      return '';
    });

    const iconLeftMarginClasses = {
      xs: '-ml-0.5 mr-2',
      sm: '-ml-0.5 mr-2',
      base: '-ml-1 mr-2',
      lg: '-ml-1 mr-3',
      xl: '-ml-1 mr-3'
    };

    const iconSizesClasses = {
      xs: 'h-4 w-4',
      sm: 'h-4 w-4  my-0.5',
      base: 'h-5 w-5',
      lg: 'h-5 w-5',
      xl: 'h-5 w-5'
    };

    return { colorClasses, sizeClasses, iconLeftMarginClasses, iconSizesClasses, roundedClasses };
  }
});
</script>
