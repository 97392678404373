<template>
  <router-view />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
  setup() {
    return {};
  }
});
</script>
