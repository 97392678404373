<template>
  <div
    class="flex flex-col gap-10 bg-primary-700 rounded-lg drop-shadow-xl divide-y divide-primary-600 px-8 py-6"
  >
    <div class="">
      <h1 class="mb-8">Complétez vos informations</h1>
      <div class="flex flex-col">
        <div class="flex flex-row gap-3">
          <Input
            class="flex-1"
            label="Prénom"
            name="firstName"
            v-model="formValue.firstName"
            :error="formErrors.firstName"
          />
          <Input
            class="flex-1"
            label="Nom"
            name="lastName"
            v-model="formValue.lastName"
            :error="formErrors.lastName"
          />
        </div>
        <Input label="Email" name="email" v-model="formValue.email" :error="formErrors.email" />
        <Input label="Téléphone" name="phone" v-model="formValue.phone" :error="formErrors.phone" />
        <Button class="self-end mt-6" @click="createBooking()" :disabled="isPaymentDisabled">
          Accéder au paiement
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, reactive } from 'vue';
import Input from '@/components/Input';
import Button from '@/components/Button.vue';
import gql from 'graphql-tag';
import { useMutation } from '@vue/apollo-composable';
import useTentBooking from '@/composable/useTentBooking';

const INIT_BOOKING_MUTATION = gql`
  mutation InitBooking($input: InitBookingInput!) {
    initBooking(input: $input) {
      code
    }
  }
`;

const PAY_BOOKING_MUTATION = gql`
  mutation PayBooking($input: PayBookingInput!) {
    payBooking(input: $input) {
      sessionUrl
    }
  }
`;

export default defineComponent({
  components: { Input, Button },
  setup() {
    const { mutate: initBookingMutation } = useMutation(INIT_BOOKING_MUTATION);
    const { mutate: payBookingMutation } = useMutation(PAY_BOOKING_MUTATION);
    const { booking, selectedTents, options } = useTentBooking();
    const formValue = reactive({
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    });

    const formErrors = ref({});

    const validateForm = () => {
      const errors = {};
      if (!formValue.firstName?.length) {
        errors.firstName = 'Le prénom est obligatoire';
      }
      if (!formValue.lastName?.length) {
        errors.lastName = 'Le nom est obligatoire';
      }
      // eslint-disable-next-line no-useless-escape
      if (!/^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/.test(formValue.email)) {
        // eslint-disable-next-line prettier/prettier
        errors.email = 'L\'email n\'est pas valide';
      }
      // eslint-disable-next-line no-useless-escape
      if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(formValue.phone)) {
        // eslint-disable-next-line prettier/prettier
        errors.phone = 'Le numéro de téléphone n\'est pas valide';
      }
      formErrors.value = { ...errors };
    };

    const createBooking = async () => {
      validateForm();
      if (!Object.keys(formErrors.value).length) {
        const { date, nbNights, nbAdults, nbChildren } = booking.value;
        const input = {
          date,
          nbNights,
          nbAdults,
          nbChildren,
          tents: Object.values(selectedTents.value),
          options: Object.values(options.value),
          customerDetails: { ...formValue }
        };
        const { data: bookingData } = await initBookingMutation({ input });

        const bookingCode = bookingData.initBooking.code;
        const { data: stripeSessionData } = await payBookingMutation({
          input: {
            bookingCode,
            successUrl: `${process.env.VUE_APP_BASE_URL}/booking?id=${bookingCode}&success=true`,
            cancelUrl: window.location.href
          }
        });

        window.open(stripeSessionData.payBooking.sessionUrl).focus();
      }
    };

    // TODO si tentes pas dispo
    const isPaymentDisabled = false;

    return {
      createBooking,
      isPaymentDisabled,
      formValue,
      formErrors
    };
  }
});
</script>
