<template>
  <div class="flex flex-col md:flex-row gap-8">
    <div v-if="!availableTents.length" class="w-full text-center italic">
      Aucune tente n'est disponible pour cette date.
    </div>
    <div v-else class="flex flex-col gap-3">
      <div
        v-for="tent in availableTents"
        :key="tent.typeId"
        :class="[
          getSelectedNumber(tent.typeId)
            ? 'border-2 border-primary-400 drop-shadow-xl'
            : 'border border-primary-600 drop-shadow-sm'
        ]"
        class="flex flex-col md:flex-row bg-primary-700 rounded-lg"
      >
        <PhotoSlider
          class="h-56 rounded-t-lg md:rounded-l-lg md:rounded-r-none md:flex-1 md:h-full"
          :files="tent.photos.map(p => ({ ...p, ...p.attributes }))"
        />
        <div class="flex flex-col md:flex-1 gap-6 px-7 pt-4 pb-5">
          <div class="flex-1">
            <div class="text-2xl">{{ tent.name }}</div>
            <div class="text-base">{{ tent.description }}</div>
          </div>
          <div class="flex flex-col items-end">
            <div>{{ tent.capacity }} personne{{ tent.capacity > 1 ? 's' : '' }}</div>
            <div>
              {{ tent.nbAvailable }} tente{{ tent.nbAvailable > 1 ? 's' : '' }} disponible{{
                tent.nbAvailable > 1 ? 's' : ''
              }}
            </div>
            <div class="flex flex-row divide-x divide-primary-700 mt-2">
              <Button
                groupPosition="left"
                type="secondary"
                size="sm"
                :disabled="!getSelectedNumber(tent.typeId)"
                @click="removeTent(tent.typeId)"
              >
                <template #icon> <MinusIcon /> </template>
              </Button>
              <Button
                groupPosition="right"
                type="secondary"
                size="sm"
                :disabled="
                  maximumTentsReached || getSelectedNumber(tent.typeId) >= tent.nbAvailable
                "
                @click="addTent(tent.typeId)"
              >
                <template #icon> <PlusIcon /> </template>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="booking && isValidSearch" class="md:w-2/3 flex flex-col">
      <h2>Votre sélection</h2>
      <div class="mt-1 text-primary-200">
        <div class="flex flex-row items-center">
          <CalendarDaysIcon class="h-4 w-4 mr-2" />{{
            formatDate(new Date(booking.date), 'd MMM yyyy')
          }}, {{ booking.nbNights }} nuit{{ booking.nbNights > 1 ? 's' : '' }}
        </div>
        <div class="flex flex-row items-center">
          <UserGroupIcon class="h-4 w-4 mr-2" />{{ booking.nbAdults }} adulte{{
            booking.nbAdults > 1 ? 's' : ''
          }}
          <span v-if="booking.nbChildren" class="ml-1">
            et {{ booking.nbChildren }} enfant{{ booking.nbChildren > 1 ? 's' : '' }}
          </span>
        </div>
      </div>
      <div class="mt-10">
        <div
          v-for="tent in bookedTents"
          :key="tent.typeId"
          class="flex flex-row self-stretch justify-between"
        >
          <div class="font-semibold">{{ tent.nbSelected }}x {{ tent.name }}</div>
        </div>
      </div>
      <div v-if="minimumTentsReached">
        <div
          class="flex flex-row self-stretch justify-between mt-4 pt-4 border-t border-primary-700"
        >
          <div>Adultes</div>
          <div>{{ totalAdults }} €</div>
        </div>
        <div class="flex flex-row self-stretch justify-between mt-1">
          <div>Enfants</div>
          <div>{{ totalChildren }} €</div>
        </div>
        <div class="flex flex-row self-stretch justify-between mt-4">
          <div class="font-semibold">Total</div>
          <div class="font-semibold">{{ total }} €</div>
        </div>
      </div>
      <Button class="mt-10" :disabled="!minimumTentsReached" @click="book()">Je réserve</Button>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import Button from '@/components/Button.vue';
import useTentBooking from '@/composable/useTentBooking';
import { CalendarDaysIcon, UserGroupIcon, PlusIcon, MinusIcon } from '@heroicons/vue/20/solid';
import useUtils from '@/composable/useUtils';
import PhotoSlider from '@/components/PhotoSlider';
import { useRoute, useRouter } from 'vue-router';
import useAvailableTentsQuery from '@/composable/useAvailableTentsQuery';

export default defineComponent({
  components: { Button, CalendarDaysIcon, UserGroupIcon, PlusIcon, MinusIcon, PhotoSlider },
  setup() {
    const { availableTents, isValidSearch } = useAvailableTentsQuery();
    const router = useRouter();
    const route = useRoute();

    const {
      addTent,
      booking,
      getSelectedNumber,
      removeTent,
      totalAdults,
      totalChildren,
      total,
      selectedTents
    } = useTentBooking();

    const { formatDate } = useUtils();

    const book = () => {
      router.push({ name: 'cart', query: route.query });
    };

    const bookedTents = computed(() => {
      return Object.values(selectedTents.value)
        .map(tent => {
          const tentData = availableTents.value.find(t => t.typeId === tent.typeId);
          if (tentData) {
            return {
              name: tentData.name,
              nbSelected: tent.nbSelected,
              capacity: tentData.capacity
            };
          }
          return null;
        })
        .filter(t => !!t);
    });

    const maximumTentsReached = computed(() => {
      const nbTentsSelected = bookedTents.value.reduce((res, t) => res + t.nbSelected, 0);
      const nbGuests = booking.value.nbAdults + booking.value.nbChildren;
      return booking.value && nbTentsSelected >= nbGuests;
    });

    const minimumTentsReached = computed(() => {
      const bookedCapacity = bookedTents.value.reduce(
        (res, t) => res + t.capacity * t.nbSelected,
        0
      );
      const nbGuests = booking.value.nbAdults + booking.value.nbChildren;
      return booking.value && bookedCapacity >= nbGuests;
    });

    return {
      availableTents,
      addTent,
      booking,
      formatDate,
      bookedTents,
      total,
      getSelectedNumber,
      removeTent,
      maximumTentsReached,
      minimumTentsReached,
      totalAdults,
      totalChildren,
      book,
      isValidSearch
    };
  }
});
</script>
