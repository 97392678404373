<template>
  <div class="flex flex-col gap-6 mx-auto items-center mb-12 md:mb-20">
    <h1 class="text-center mb-6">Vous souhaitez planifier votre séjour chez nous ?</h1>
    <div
      v-if="isSearching"
      class="flex flex-col gap-4 md:flex-row md:items-end mx-auto md:gap-2 bg-primary-700 px-7 pt-4 pb-5 rounded-lg drop-shadow-xl"
    >
      <div class="grid grid-cols-2 sm:flex gap-2">
        <Input label="Date" name="date" type="date" v-model="bookingFormValue.date" disableError />
        <Input
          label="Nuits"
          name="nbNights"
          type="number"
          v-model="bookingFormValue.nbNights"
          disableError
        />
        <Input
          label="Adultes"
          name="nbAdults"
          type="number"
          v-model="bookingFormValue.nbAdults"
          disableError
        />
        <Input
          label="Enfants"
          name="nbChildren"
          type="number"
          v-model="bookingFormValue.nbChildren"
          disableError
        />
      </div>

      <Button @click="search()" :disabled="!isFormValid"> Rechercher </Button>

      <XMarkIcon
        @click="close()"
        class="cursor-pointer absolute top-2 right-2 h-6 w-6 text-primary-200"
      />
    </div>
    <Button v-else @click="open()">
      <template #icon-before><MagnifyingGlassIcon /></template>
      Lancer la recherche
    </Button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Input from '@/components/Input.vue';
import Button from '@/components/Button.vue';
import useAvailableTentsForm from '@/composable/useAvailableTentsForm';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/20/solid';

export default defineComponent({
  components: { Input, Button, MagnifyingGlassIcon, XMarkIcon },
  setup() {
    const {
      formValue: bookingFormValue,
      openForm,
      clearForm,
      isSearching,
      search,
      isFormValid
    } = useAvailableTentsForm();

    const open = () => {
      openForm();
    };

    const close = () => {
      clearForm();
    };

    return { open, close, bookingFormValue, search, isSearching, isFormValid };
  }
});
</script>
